import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { setProject } from '../../app/redux/reducers/userSlice';
import useStyles from './SpentTimeCreate.styles';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTimesheetIcon from '../../../../packages/common/shared-ui/src/icons/CalendarTimesheetIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import {
  createOrUpdateTimesheets,
  getProject,
  getTimesheetEditBulk,
  getTimesheetProjectInfoSpentTime,
  getTimesheetProjects,
  saveTimesheetEditBulk,
} from '../../../../packages/common/api';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import ClearFormFieldIcon from '../../../../packages/common/shared-ui/src/icons/ClearFormFieldIcon';

const SpentTimeCreate = ({ intl }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const userAnchorEl = useRef();
  const currentUser = useSelector(state => state.user.info);
  // createOrUpdateTimesheets
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(Boolean(state?.rowId));
  const [createError, setCreateError] = useState(null);
  const [isCommentEdited, setIsCommentEdited] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [isIssueSelectOpen, setIsIssueSelectCanOpen] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [ativitiesList, setAativitiesList] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectInfo, setProjectInfo] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(Object.fromEntries(searchParams.entries()));
  const [isBulk, setIsBulk] = useState(false);
  const [pageTitle, setPageTitle] = useState(intl.formatMessage({ id: 'page_title_spentTime' }));
  const [timeSheets, setTimeSheets] = useState([]);
  const [newSpentTimeObject, setNewSpentTimeObject] = useState({
    redmineId: state?.rowId ? state.rowId : 0,
    projectId: state?.projectId ? state.projectId : 0,
    issueId: state?.issueId ? state.issueId : 0,
    hours: Number(state?.hours) ? Number(state.hours) : 0,
    comments: '',
    spentOn: state?.spentOn ? state.spentOn : null,
    userId: state?.userId ? state.userId : 0,
    isOvertime: state?.isOvertime ? state.isOvertime : false,
    activityId: '',
  });
  const urlParams = useRef(new URLSearchParams(location.search));
  const [changedFields, setChangedFields] = useState([]);
  const [deletedFields, setDeletedFields] = useState([]);
  const [costsField, setCostsField] = useState('');

  useEffect(() => {
    location.pathname.includes('bulk') && urlParams.current.get('timesheets') && setIsBulk(true);
    isBulk && setPageTitle(intl.formatMessage({ id: 'page_title_spentTime_bulk' }));
  }, [intl, isBulk, location, location.pathname]);
  useEffect(() => {
    async function fetchProject(slug) {
      const response = await getProject(slug);
      dispatch(setProject(response));
    }

    let lockedProjectId = '';
    if (searchParams?.get('locked')) {
      let filtersQuery = searchParams?.get('filters');
      if (typeof filtersQuery != 'undefined' && filtersQuery != null) {
        if (filtersQuery.includes('$$')) {
          let filters = searchParams?.get('filters').split('$$');
          filters?.forEach(filter => {
            if (filter.includes('timesheet.project')) {
              lockedProjectId = filter.split(';')[2];
            }
          });
        } else {
          lockedProjectId = filtersQuery.split(';')[2];
        }
      }
    }

    if (lockedProjectId.length > 0 && typeof lockedProjectId != 'undefined') {
      setNewSpentTimeObject(prevState => ({
        ...prevState,
        projectId: Number(lockedProjectId),
      }));
      getProjectInfo({
        projectId: lockedProjectId,
        rowId: 0,
      });
    }
    if (!isBulk && state.hasOwnProperty('projectId')) {
      getProjectInfo({
        projectId: state.projectId,
        rowId: state.rowId,
      });
    }

    fetchProject(lockedProjectId);
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjects, state, getProjectInfo, searchParams, setNewSpentTimeObject, dispatch]);

  useEffect(() => {
    let ignore = false;
    async function getEditBulk() {
      let params = { timesheets: urlParams.current.get('timesheets').replaceAll(',', '||') };
      //setLoading(true);
      const { data: response } = await getTimesheetEditBulk(params);
      if (!ignore) {
        return response;
      }
    }
    if (isBulk) {
      getEditBulk()
        .then(res => {
          setTimeSheets(res.timesheets);
        })
        .catch(error => console.error(error, 'ERROR IN GET'));
      return () => {
        ignore = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulk]);

  const handleChangeIssueSelectStatus = useCallback(
    ({ isClose }) => {
      if (isClose) {
        return setIsIssueSelectCanOpen(false);
      }
      if (newSpentTimeObject.userId) {
        setIsIssueSelectCanOpen(true);
      }
    },
    [newSpentTimeObject.userId],
  );

  const getProjectInfo = useCallback(
    async ({ projectId, rowId }) => {
      let params = {
        project: projectId,
      };
      state.issueId && (params.issue = state.issueId);
      const { data: currentProject } = await getTimesheetProjectInfoSpentTime({ params, timesheetId: rowId });
      setProjectInfo(currentProject);
      setAativitiesList(currentProject.activities);
      setNewSpentTimeObject(prevState => ({
        ...prevState,
        issueId: currentProject.project.issues.find(issue => issue.isChecked)?.issueId ?? 0,
        userId: currentProject.userId,
        projectId: Number(projectId),
        isOvertime: currentProject.isOvertime,
        comments: isCommentEdited ? prevState.comments : currentProject.comments,
        activityId: currentProject.activityId,
      }));
      setCostsField(currentProject.costs ? currentProject.costs : '');
      setIssueList(currentProject.project.issues);
      setUsers(currentProject.project.users);
      setIsLoading(false);
    },
    [isCommentEdited, state?.issueId],
  );

  const handleChangeProject = useCallback(
    async e => {
      try {
        setIsLoading(true);
        await getProjectInfo({ projectId: e.target.value, rowId: newSpentTimeObject.rowId });
        !changedFields.includes('projectId') && setChangedFields(prev => [...prev, 'projectId']);
      } catch (error) {
        setNewSpentTimeObject(prevState => ({ ...prevState, projectId: 0, issueId: 0, userId: 0 }));
        setIssueList([]);
        setUsers([]);
        setPermissionError(true);
        setIsLoading(false);
        setTimeout(() => setPermissionError(false), 2500);
      }
    },
    [changedFields, getProjectInfo, newSpentTimeObject.rowId],
  );

  const handleChangeUser = useCallback(async e => {
    setNewSpentTimeObject(prevState => ({ ...prevState, userId: e.target.value }));
    // const issues getAnotherTimesheets(e.target.value);
  }, []);

  const handleChangeActivity = useCallback(
    async e => {
      !changedFields.includes('activityId') && setChangedFields(prev => [...prev, 'activityId']);
      setNewSpentTimeObject(prevState => ({ ...prevState, activityId: e.target.value }));
    },
    [changedFields],
  );
  const handleChangeIssue = useCallback(
    async e => {
      !changedFields.includes('issueId') && setChangedFields(prev => [...prev, 'issueId']);
      setNewSpentTimeObject(prevState => ({ ...prevState, issueId: e.target.value }));
    },
    [changedFields],
  );
  const handleSelectOvertime = useCallback(
    (v, clear = false) => {
      !changedFields.includes('isOvertime') && setChangedFields(prev => [...prev, 'isOvertime']);
      clear && !deletedFields.includes('isOvertime') && setDeletedFields(prev => [...prev, 'isOvertime']);
      const value = !clear ? v : false;
      setNewSpentTimeObject(prevState => ({ ...prevState, isOvertime: value }));
    },
    [changedFields, deletedFields],
  );

  const handleChangeDate = useCallback(
    newDate => {
      !changedFields.includes('spentOn') && setChangedFields(prev => [...prev, 'spentOn']);
      setNewSpentTimeObject(prevState => ({ ...prevState, spentOn: dayjs(newDate).format('YYYY-MM-DD') }));
    },
    [changedFields],
  );

  const handleChangeHours = useCallback(
    e => {
      !changedFields.includes('hours') && setChangedFields(prev => [...prev, 'hours']);
      const regex = /^[0-9]{1,3}([,.][0-9]{1,2})*$/;
      setNewSpentTimeObject(prevState => ({
        ...prevState,
        hours: regex.test(e.target.value) ? Number(e.target.value) : '',
      }));
    },
    [changedFields],
  );

  const handleChangeComment = useCallback(
    (e, clear = false) => {
      !changedFields.includes('comments') && setChangedFields(prev => [...prev, 'comments']);
      clear && !deletedFields.includes('comments') && setDeletedFields(prev => [...prev, 'comments']);
      setIsCommentEdited(true);
      setNewSpentTimeObject(prevState => ({ ...prevState, comments: !clear ? e.target.value : e }));
    },
    [changedFields, deletedFields],
  );

  const handleSaveTimesheet = useCallback(async () => {
    try {
      if (
        (!isBulk && newSpentTimeObject.issueId === 0) ||
        (!isBulk && !newSpentTimeObject.spentOn) ||
        (!isBulk && newSpentTimeObject.hours === 0)
      ) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setCreateError('Проверьте правильность заполняемых данных');
        return setTimeout(() => setCreateError(null), 2500);
      }
      if (isBulk) {
        let values = {};
        changedFields.map(field => {
          values[field] = newSpentTimeObject[field];
        });
        const data = { ids: timeSheets.map(item => Number(item.timeEntryId)), timesheet: values };
        await saveTimesheetEditBulk(data);
      } else {
        setNewSpentTimeObject({
          redmineId: 0,
          projectId: 0,
          issueId: 0,
          hours: 0,
          comments: '',
          spentOn: null,
          userId: 0,
          activityId: 10,
          isOvertime: false,
        });
        await createOrUpdateTimesheets([newSpentTimeObject]);
      }
      setIsLoading(true);
      handleGoBack();
    } catch (error) {
      console.error('ERROR WITH CREATE TIMESHEET QUERY');
    }
  }, [isBulk, newSpentTimeObject, handleGoBack, changedFields, timeSheets]);

  const handleSaveTimesheetAndContinue = useCallback(async () => {
    try {
      if (newSpentTimeObject.issueId === 0 || !newSpentTimeObject.spentOn || newSpentTimeObject.hours === 0) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setCreateError('Проверьте правильность заполняемых данных');
        return setTimeout(() => setCreateError(null), 2500);
      }
      setIsLoading(true);
      await createOrUpdateTimesheets([newSpentTimeObject]);
      setNewSpentTimeObject(prevState => ({
        ...prevState,
        redmineId: 0,
        issueId: 0,
        hours: 0,
        comments: '',
        spentOn: null,
        activityId: 15,
        isOvertime: false,
      }));
      setIsLoading(false);
    } catch (error) {
      console.error('ERROR WITH CREATE AND CONTINUE');
    }
  }, [newSpentTimeObject]);

  const handleGoBack = useCallback(async () => {
    const locked = searchParams?.get('locked');
    const filters = searchParams?.get('filters');

    if (!state.savedQueryId) {
      if (locked && filters) {
        return navigate(`/spentTime?filters=${filters}&locked=${locked}`);
      }
      if (filters) {
        return navigate(`/spentTime?filters=${filters}`);
      }

      navigate('/spentTime');
    } else {
      locked
        ? navigate(`/spentTime/query/${state.savedQueryId}?projectId=${newSpentTimeObject.projectId}&locked=${locked}`)
        : navigate(`/spentTime/query/${state.savedQueryId}`);
    }
  }, [navigate, searchParams, state.savedQueryId, newSpentTimeObject.projectId]);

  const handleCancelCreate = useCallback(() => {
    return handleGoBack();
  }, [handleGoBack]);

  const getProjects = useCallback(async () => {
    const { data: projects } = await getTimesheetProjects();
    setAvailableProjects(projects);
  }, []);
  const handleEditLink = useCallback(
    item => {
      return `/spentTime/create${location.search}&userId=${item.userId}&projectId=${item.projectId}&issueId=${item.issueId}&rowId=${item.timeEntryId}&spentOn=${item.spentOn}&hours=${item.hours}&isOverTime=${item.isOvertime}`;
    },
    [location.search],
  );
  return (
    <>
      {isLoading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid container direction="column">
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">{pageTitle}</Typography>
            <Box display="flex" flexWrap="nowrap">
              <Button onClick={handleSaveTimesheet} variant="defaultBluePrimary">
                <FormattedMessage id="save_text" />
              </Button>
              {!isBulk && !state.hasOwnProperty('projectId') && (
                <Box marginLeft={2.5}>
                  <Button onClick={handleSaveTimesheetAndContinue} variant="defaultBluePrimary">
                    <FormattedMessage id="save_text_and_go" />
                  </Button>
                </Box>
              )}
              <Box marginLeft={2.5}>
                <Button onClick={handleCancelCreate} variant="defaultBluePrimary">
                  <FormattedMessage id="cancel" />
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid container direction={'column'} sx={{ mt: '4px' }}>
            {timeSheets &&
              timeSheets.length > 0 &&
              timeSheets.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Typography variant="h5">
                      <Link to={handleEditLink(item)} target={'_blank'} className={classes.issueLink}>
                        {item?.timeEntryName}
                      </Link>
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
          {createError && (
            <Typography variant="h5" fontWeight={600} color="#E03737">
              {createError}
            </Typography>
          )}
          <Grid container marginTop={createError ? 1 : 6} direction="column" className={classes.createSpentTimeOverlay}>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Проект
                  {!isBulk && <span className={classes.requiredFieldStar}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={5} position="relative">
                <Select
                  fullWidth
                  variant="outlined"
                  error={permissionError}
                  onChange={handleChangeProject}
                  value={newSpentTimeObject.projectId}
                  IconComponent={() => (
                    <Box className={classes.groupIcon}>
                      <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" right="10px" />
                    </Box>
                  )}
                  renderValue={value => {
                    const currentGroupObj = availableProjects.find(item => item.projectId === value);
                    if (value === newSpentTimeObject.projectId) {
                      return currentGroupObj
                        ? getTranslatedText(intl, 'filter', currentGroupObj.projectName, currentGroupObj.projectName)
                        : getTranslatedText(intl, '', 'choose', 'Choose');
                    }
                  }}
                  defaultValue={0}
                  MenuProps={{ className: classes.optionsGroupMenu }}
                >
                  {availableProjects.map(project => {
                    return (
                      <MenuItem value={project.projectId} key={project.projectId}>
                        <Typography variant="h5" color="#212346">
                          {project.projectName}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
                {permissionError && (
                  <Box position="absolute" top="90%">
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h6" marginBottom={1} color="#E03737">
                      Проект закрыт или нет списка задач
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Пользователь
                  {!isBulk && <span className={classes.requiredFieldStar}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={5} ref={userAnchorEl}>
                <Select
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeUser}
                  value={newSpentTimeObject.userId}
                  disabled={newSpentTimeObject.projectId === 0}
                  IconComponent={() => (
                    <Box className={classes.groupIcon}>
                      <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" right="10px" />
                    </Box>
                  )}
                  renderValue={value => {
                    const currentGroupObj = users.find(item => item.userId === value);
                    if (value === newSpentTimeObject.userId) {
                      return currentGroupObj
                        ? getTranslatedText(intl, 'filter', currentGroupObj.userName, currentGroupObj.userName)
                        : getTranslatedText(intl, '', 'choose', 'Choose');
                    }
                  }}
                  defaultValue={0}
                  MenuProps={{ className: classes.optionsGroupMenu }}
                  className={classes.createSelect}
                  // color="secondary"
                >
                  {users.map(user => {
                    // console.log(user);
                    return (
                      <MenuItem value={user.userId} key={user.userId}>
                        <Typography variant="h5" color="#212346">
                          {user.userName}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Задача
                  {!isBulk && <span className={classes.requiredFieldStar}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  fullWidth
                  variant="outlined"
                  onOpen={handleChangeIssueSelectStatus}
                  disabled={newSpentTimeObject.projectId === 0}
                  onClose={() => handleChangeIssueSelectStatus({ isClose: true })}
                  onChange={handleChangeIssue}
                  value={newSpentTimeObject.issueId}
                  className={classes.createSelect}
                  IconComponent={() => (
                    <Box className={classes.groupIcon}>
                      <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" right="10px" />
                    </Box>
                  )}
                  renderValue={value => {
                    const currentGroupObj = issueList.find(item => item.issueId === value);
                    if (value === newSpentTimeObject.issueId) {
                      return currentGroupObj
                        ? getTranslatedText(intl, 'filter', currentGroupObj.issueName, currentGroupObj.issueName)
                        : getTranslatedText(intl, '', 'choose', 'Choose');
                    }
                  }}
                  defaultValue={0}
                  MenuProps={{ className: classes.optionsGroupMenu }}
                >
                  {issueList.map(issue => {
                    return (
                      <MenuItem value={issue.issueId} key={issue.issueId}>
                        <Typography variant="h5" color="#212346">
                          {issue.issueName}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Дата
                  {!isBulk && <span className={classes.requiredFieldStar}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['day']}
                    disableMaskedInput
                    components={{
                      OpenPickerIcon: () => (
                        <CalendarTimesheetIcon style={{ right: 10, width: 16, height: 16 }} viewBox="0 0 16 16" />
                      ),
                      LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                      RightArrowIcon: () => (
                        <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                      ),
                      SwitchViewIcon: () => <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />,
                      SwitchViewButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      RightArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      LeftArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                    }}
                    renderInput={params => {
                      return (
                        <Box>
                          <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, readOnly: true }}
                            helperText={null}
                          />
                        </Box>
                      );
                    }}
                    value={newSpentTimeObject.spentOn}
                    onChange={handleChangeDate}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Час(а, ов)
                  {!isBulk && <span className={classes.requiredFieldStar}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  type="number"
                  placeholder="0,00"
                  value={newSpentTimeObject.hours}
                  onChange={handleChangeHours}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Комментарий
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Grid container direction={'row'}>
                  <OutlinedInput
                    className={classes.textField}
                    disabled={deletedFields.includes('comments')}
                    sx={{ flex: 1 }}
                    onChange={handleChangeComment}
                    placeholder={`${intl.formatMessage({ id: 'enter' })}`}
                    value={newSpentTimeObject.comments}
                  />
                  {isBulk && (
                    <Tooltip
                      PopperProps={{ className: classes.tooltipForm }}
                      placement="top-start"
                      title={intl.formatMessage({ id: 'issue_clear_tooltip' })}
                    >
                      <IconButton
                        onClick={() => handleChangeComment('', true)}
                        disableRipple
                        sx={{ padding: 0, marginLeft: '2px' }}
                      >
                        <ClearFormFieldIcon color={deletedFields.includes('comments') ? '#E4E4EF' : '#7174AC'} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Деятельность
                  {!isBulk && <span className={classes.requiredFieldStar}>*</span>}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  fullWidth
                  variant="outlined"
                  onOpen={handleChangeIssueSelectStatus}
                  disabled={newSpentTimeObject.projectId === 0}
                  onClose={() => handleChangeIssueSelectStatus({ isClose: true })}
                  onChange={handleChangeActivity}
                  value={newSpentTimeObject.activityId}
                  className={classes.createSelect}
                  IconComponent={() => (
                    <Box className={classes.groupIcon}>
                      <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" right="10px" />
                    </Box>
                  )}
                  renderValue={value => {
                    const currentGroupObj = ativitiesList.find(item => item.activityId === value);
                    if (value === newSpentTimeObject.activityId) {
                      return currentGroupObj.activityName;
                    }
                  }}
                  defaultValue={0}
                  MenuProps={{ className: classes.optionsGroupMenu }}
                >
                  {ativitiesList.map(activity => {
                    return (
                      <MenuItem value={activity.activityId} key={activity.activityId}>
                        <Typography variant="h5" color="#212346">
                          {activity.activityName}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
              <Grid item xs={4}>
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                <Typography variant="h5" padding="8px 16px">
                  Переработка
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Grid container direction={'row'}>
                  <Checkbox
                    disabled={deletedFields.includes('isOvertime')}
                    disableRipple
                    icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                    checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                    checked={newSpentTimeObject.isOvertime}
                    onChange={e => handleSelectOvertime(e.target.checked)}
                  />
                  {isBulk && (
                    <Tooltip
                      PopperProps={{ className: classes.tooltipForm }}
                      placement="top-start"
                      title={intl.formatMessage({ id: 'issue_clear_tooltip' })}
                    >
                      <IconButton
                        onClick={() => handleSelectOvertime(false, true)}
                        disableRipple
                        sx={{ padding: 0, marginLeft: '2px' }}
                      >
                        <ClearFormFieldIcon color={deletedFields.includes('isOvertime') ? '#E4E4EF' : '#7174AC'} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isBulk && costsField && (
              <Grid container wrap="nowrap" alignItems="center" marginTop={4}>
                <Grid item xs={4}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography variant="h5" padding="8px 16px">
                    Стоимость факт
                  </Typography>
                </Grid>
                <Grid item>
                  <OutlinedInput
                    className={classes.textField}
                    disabled
                    type="number"
                    placeholder="0,00"
                    value={costsField}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default injectIntl(SpentTimeCreate);
