import React, { memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getFilterSubItems, getQueryActivity, getQueryProjects, getQueryTrackers, getQueryUser } from 'Common/api';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';

import useStyles from './CustomFillter.styles';
import CheckboxIcon from '../../icons/CheckboxIcon';
import CheckboxIconChecked from '../../icons/CheckboxIconChecked';
import ChevronIcon from '../../icons/ChevronIcon';
import FilterDateBetween from './components/FilterDateBetween';
import weekday from 'dayjs/plugin/weekday';
import {
  getFilterIssuePriorities,
  getFilterIssueRoles,
  getFilterIssueStatuses,
  getFilterIssueUserGroups,
  getFilterIssueWatcher,
  getFilterProjectStatuses,
  getQueryIssues,
  getTimesheets,
  getFilterCalculationTypes,
} from '../../../../api';
import { useSelector } from 'react-redux';
import { useDebouncedFunction } from '../../../../utils/debounce';
import { store } from '../../../../../../src/components/app/redux';

const CustomFilter = ({
  // setValues MUST INCLUDE "chosenFirstStepItems" OF TYPE OBJECT!
  setValues,
  resplan,
  firstStepItems = [],
  secondStepItems = [],
  chosenFirstStepItems = {},
  chosenSecondStepItems,
  defaultStep = 0,
  fixedAnchorEl,
  firstStepValues,
  isClear,
  filtersOnlySelect,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const state = store.getState();
  const [filterOptions, setFilterOptions] = useState({
    availableFirstStepItems: [
      ...firstStepItems.map((filter, index) => {
        const textFilters = ['text', 'string'];
        const filterLabel = getTranslatedText(intl, 'filter', filter.fieldId, filter.fieldName);
        return {
          label: filterLabel,
          value: filter.fieldId,
          fieldType: filter.fieldType,
          type: textFilters.includes(filter.fieldType) ? 'in' : 'eq',
          notParsedType: textFilters.includes(filter.fieldType) ? 'in' : 'eq',
          locked: filter.locked ? filter.locked : false,
          subLabels: [],
          id: index,
        };
      }),
    ],
    // IF OBJECT HAS "value" ATTRIBUTE = OBJECT NOT FORMATTED
    chosenFirstStepItems: chosenFirstStepItems,
    secondStepInputText: '',
    availableSecondStepItems: secondStepItems.map(item => ({
      valueName: item.valueName,
      valueId: item.valueId,
      isChecked: item.isChecked,
    })),
    chosenSecondStepItems: chosenSecondStepItems,
    firstStepValues: firstStepValues || {
      value: '',
      valueName: '',
      valueId: null,
      fieldType: '',
      type: '',
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFirstStepPopperOpen, setIsFirstStepPopperOpen] = useState(false);
  const [isSecondStepPopperOpen, setIsSecondStepPopperOpen] = useState(false);
  const [isSecondStepLoading, setIsSecondStepLoading] = useState(false);
  const [isDefaultFirstStepOpen, setIsDefaultFirstStepOpen] = useState(false);
  const [isClearDate, setIsClearDate] = useState(isClear);

  useEffect(() => {
    if (defaultStep === 0 && filterOptions.chosenFirstStepItems !== chosenFirstStepItems) {
      setFilterOptions(prevState => ({
        ...prevState,
        chosenFirstStepItems: chosenFirstStepItems,
      }));
    }
  }, [chosenFirstStepItems, defaultStep, filterOptions.chosenFirstStepItems, isClear]);

  const handleFormatFirstStepItems = useCallback(() => {
    const filteredFilters = Object.entries(filterOptions.chosenFirstStepItems).reduce((acc, rec) => {
      if (rec[0].split('').at(-1) === '_') {
        if (rec[1].subLabels?.length < 1) {
          return acc;
        }
        return {
          ...acc,
          [rec[0].split('').slice(0, -1).join('')]: rec[1],
        };
      }
      return {
        ...acc,
        [rec[0]]: rec[1],
      };
    }, {});
    setFilterOptions(prevState => {
      return {
        ...prevState,
        chosenFirstStepItems: filteredFilters,
      };
    });
    return setValues(filteredFilters);
  }, [filterOptions.chosenFirstStepItems, setValues]);

  const handleCloseAll = useCallback(() => {
    setIsDefaultFirstStepOpen(false);
    setIsSecondStepPopperOpen(false);
    setIsFirstStepPopperOpen(false);
    const notParsedFilter = Object.keys(filterOptions.chosenFirstStepItems).find(
      label => label.split('').at(-1) === '_',
    );
    if (notParsedFilter) {
      handleFormatFirstStepItems();
    }
  }, [filterOptions.chosenFirstStepItems, handleFormatFirstStepItems]);

  const handleOpenFilterFirstStep = useCallback(
    e => {
      if (!anchorEl) {
        setAnchorEl(e.currentTarget);
      }
      if (!isSecondStepPopperOpen) {
        setIsFirstStepPopperOpen(true);
      } else {
        setIsFirstStepPopperOpen(false);
        setIsSecondStepPopperOpen(false);
      }
      forceUpdate();
    },
    [anchorEl, isSecondStepPopperOpen],
  );

  const handleGetNewQueryIssues = useCallback(
    async ({ id, value }) => {
      try {
        const { data: newSecondStepItems } = await getQueryIssues({
          searchText: value
            ? value
            : filterOptions.chosenFirstStepItems[id]?.subLabels
                .filter(item => item)
                .map(item => item.valueId)
                .join('||'),
        });
        if (!newSecondStepItems) {
          return;
        }
        const updatedItems = newSecondStepItems.map(item => ({
          ...item,
          valueName: item.valueName.trim(),
        }));
        setIsSecondStepLoading(true);
        setFilterOptions(prevState => ({
          ...prevState,
          availableSecondStepItems: updatedItems,
        }));
        setIsSecondStepLoading(false);
        return updatedItems;
      } catch (error) {
        console.error('ERROR WITH GET ISSUES', error);
      }
    },
    [filterOptions.chosenFirstStepItems],
  );

  const handleChangeFilter = useCallback(
    async (e, newValue, _, { option }) => {
      const isOptionIncludesValues = Object.values(filterOptions.chosenFirstStepItems)
        .map(item => item.value)
        .includes(option.value);
      setAnchorEl(fixedAnchorEl.current);
      setIsFirstStepPopperOpen(false);
      setIsSecondStepLoading(true);
      setIsSecondStepPopperOpen(true);
      if (isOptionIncludesValues && !option.hasOwnProperty('isUpdateFirstStep')) {
        const filteredItems = Object.values(filterOptions.chosenFirstStepItems).reduce((acc, rec) => {
          if (rec.value === option.value) {
            return acc;
          }
          return {
            ...acc,
            [rec.value]: rec,
          };
        }, {});
        setValues(filteredItems);
        setIsSecondStepLoading(false);
        setIsSecondStepPopperOpen(false);
        return setFilterOptions(prevState => ({
          ...prevState,
          chosenFirstStepItems: filteredItems,
        }));
      }
      let rawSubItems = [];
      let projectId = state.user.project?.redmineId === undefined ? null : state.user.project.redmineId;
      try {
        switch (option.fieldType) {
          case 'project': {
            const { data: queryProjects } = await getQueryProjects();
            rawSubItems = queryProjects;
            break;
          }
          case 'issue': {
            const items = await handleGetNewQueryIssues({ id: option.value });
            if (items) {
              rawSubItems = items.map(item => ({ ...item, isChecked: true }));
            }
            break;
          }
          case 'tracker': {
            const { data: queryTrackers } = await getQueryTrackers(projectId);
            rawSubItems = queryTrackers;
            break;
          }
          case 'user': {
            const { data: queryUser } = await getQueryUser(option.value, projectId);
            queryUser[0].valueName = getTranslatedText(intl, '', queryUser[0].valueName.toLowerCase(), 'Me');
            rawSubItems = queryUser;
            break;
          }
          case 'activity': {
            const { data: queryActivity } = await getQueryActivity();
            rawSubItems = queryActivity;
            break;
          }
          case 'list': {
            const { data: filterSubItems } = await getFilterSubItems(option.value);
            rawSubItems = filterSubItems;
            break;
          }
          case 'calculation_types': {
            const { data: filterSubItems } = await getFilterCalculationTypes();
            rawSubItems = filterSubItems;
            break;
          }
          case 'issue_status': {
            const { data: issueStatuses } = await getFilterIssueStatuses(projectId);
            rawSubItems = issueStatuses;
            break;
          }
          case 'issue_watchers': {
            const { data: issueWatchers } = await getFilterIssueWatcher(projectId);
            issueWatchers[0].valueName = getTranslatedText(intl, '', issueWatchers[0].valueName.toLowerCase(), 'Me');
            rawSubItems = issueWatchers;
            break;
          }
          case 'user_groups': {
            const { data: userGroups } = await getFilterIssueUserGroups();
            rawSubItems = userGroups;
            break;
          }
          case 'issue_priority': {
            const { data: issuePriorities } = await getFilterIssuePriorities();
            rawSubItems = issuePriorities;
            break;
          }
          case 'user_roles': {
            const { data: userRoles } = await getFilterIssueRoles();
            rawSubItems = userRoles;
            break;
          }
          case 'status': {
            const { data: projectStatuses } = await getFilterProjectStatuses();
            rawSubItems = projectStatuses;
            break;
          }
          case 'text':
          case 'file': {
            setIsDefaultFirstStepOpen(true);
            break;
          }
          case 'bool': {
            setIsDefaultFirstStepOpen(true);
            break;
          }
          case 'float': {
            setIsDefaultFirstStepOpen(true);
            rawSubItems = [];
            break;
          }
          case 'int': {
            break;
          }
          case 'date': {
            setIsDefaultFirstStepOpen(true);
            break;
          }
          default: {
            break;
          }
        }
        if (option.isUpSelectedItems) {
          const currentValue = Object.values(filterOptions.chosenFirstStepItems).filter(
            item => item.value === option.value,
          );
          currentValue.forEach(item => {
            const currentSubLabels = item.subLabels.map(subLabel => subLabel && Number(subLabel.valueId));
            currentSubLabels.forEach(rec => {
              const currentItem = rawSubItems.find(subItem => Number(subItem.valueId) === Number(rec));
              const currentItemIndex = rawSubItems.indexOf(currentItem);
              rawSubItems.splice(0, 0, rawSubItems[currentItemIndex]);
              rawSubItems.splice(currentItemIndex + 1, 1);
            });
          });
        }
        handleFormatFirstStepItems();
        setValues(
          option.hasOwnProperty('isUpdateFirstStep')
            ? { ...filterOptions.chosenFirstStepItems }
            : {
                ...filterOptions.chosenFirstStepItems,
                [`${option.value}_`]: {
                  label: option.label,
                  value: option.value,
                  subLabels: [],
                  fieldType: option.fieldType,
                  type: option.type,
                  notParsedType: option.type,
                  locked: option.locked ? option.locked : false,
                },
              },
        );
        setFilterOptions(prevState => {
          return {
            ...prevState,
            availableSecondStepItems: rawSubItems,
            secondStepInputText: '',
            // SET TEMPORARY NAME FOR GET REQUEST [EXAMPLE: 'timesheet.project_']
            chosenFirstStepItems: option.hasOwnProperty('isUpdateFirstStep')
              ? { ...filterOptions.chosenFirstStepItems }
              : {
                  ...filterOptions.chosenFirstStepItems,
                  [`${option.value}_`]: {
                    label: option.label,
                    value: option.value,
                    subLabels: [],
                    fieldType: option.fieldType,
                    type: option.type,
                    notParsedType: option.type,
                    locked: option.locked ? option.locked : false,
                  },
                },
            // SAVE TEMPORARY FIRST STEP VALUES
            firstStepValues: {
              value: option.value,
              valueName: option.label,
              id: prevState.firstStepValues.id ? prevState.firstStepValues.id : option.id,
              fieldType: option.fieldType,
              type: option.type,
            },
          };
        });
      } catch (error) {
        handleFormatFirstStepItems();
      } finally {
        setIsSecondStepLoading(false);
      }
    },
    [
      handleGetNewQueryIssues,
      filterOptions.chosenFirstStepItems,
      state,
      fixedAnchorEl,
      setValues,
      handleFormatFirstStepItems,
      intl,
    ],
  );
  const formatDateToMonthYear = dateString => {
    const date = dayjs(dateString);
    return `${date.format('MMMM YYYY')}`;
  };
  const formatDateToYear = dateString => {
    const date = dayjs(dateString);
    return `${date.format('YYYY')}`;
  };
  const getNewRowType = useCallback(value => {
    switch (value) {
      case '>t-':
        value = 'gt';
        return value;
      case '<t-':
        value = 'lt';
        return value;
      case '><t-':
        value = 'gt';
        return value;
      case 't-':
        value = 'eq';
        return value;
      default:
        return value;
    }
  }, []);

  const handleChooseSubItem = useCallback(
    ({ option, isMultiple = true, type, itemIndex }) => {
      const chosenItemsWithoutCurrent = Object.entries(filterOptions.chosenFirstStepItems).reduce((acc, rec) => {
        let valueForDelete = filterOptions.firstStepValues.value;
        if (option.hasOwnProperty('item') && option.hasOwnProperty('delete')) {
          valueForDelete = option.item;
        }
        if (rec[1].value === valueForDelete) {
          return acc;
        }
        return {
          ...acc,
          [rec[0]]: rec[1],
        };
      }, {});
      const subLabels = filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels || [];
      const isOptionIncludeLabel = subLabels
        .map(label => {
          if (!label) {
            return;
          }
          return label.valueId;
        })
        .includes(option.valueId);
      const formattedFirstStepItems = Object.values(filterOptions.chosenFirstStepItems).reduce((acc, rec) => {
        if (!rec.hasOwnProperty('value')) {
          return acc;
        }
        if (`${rec.value}`.split('').at(-1) === '_') {
          return acc;
        }
        return {
          ...acc,
          [rec.value]: rec,
        };
      }, {});
      if (option.hasOwnProperty('delete')) {
        setIsSecondStepPopperOpen(false);
        setFilterOptions(prevState => ({
          ...prevState,
          chosenFirstStepItems: chosenItemsWithoutCurrent,
        }));
        return setValues(
          defaultStep === 0
            ? chosenItemsWithoutCurrent
            : {
                ...chosenItemsWithoutCurrent,
                [`${filterOptions.firstStepValues.value}_`]: {
                  valueName: filterOptions.firstStepValues.valueName,
                  label: filterOptions.firstStepValues.valueName,
                  value: filterOptions.firstStepValues.value,
                  type: getNewRowType(filterOptions.firstStepValues.type),
                  fieldType: filterOptions.firstStepValues.fieldType,
                  subLabels: [],
                },
              },
        );
      }
      if (subLabels.length === 1 && isOptionIncludeLabel && itemIndex === 0) {
        if (defaultStep === 0) {
          return;
        }
      }
      const newChosenFirstStepItems = {
        ...formattedFirstStepItems,
        [filterOptions.firstStepValues.value]: {
          ...filterOptions.chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
          ...filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value],
          valueName: filterOptions.firstStepValues.valueName,
          label: filterOptions.firstStepValues.valueName,
          value: filterOptions.firstStepValues.value,
          type: getNewRowType(filterOptions.firstStepValues.type),
          fieldType: filterOptions.firstStepValues.fieldType,
          subLabels:
            filterOptions.firstStepValues.type === 'null' || filterOptions.firstStepValues.type === 'all'
              ? [null]
              : isOptionIncludeLabel || option.value === 'issue.issue_id' || option.value === 'issue.parent_issue'
              ? subLabels.filter(label => label.valueId !== option.valueId) || []
              : isMultiple
              ? [...subLabels, option]
              : [option],
        },
      };
      if (itemIndex === 1) {
        newChosenFirstStepItems[filterOptions.firstStepValues.value].subLabels = [subLabels[0], option];
      }
      if (itemIndex === 0 && filterOptions.firstStepValues.type === 'btw') {
        newChosenFirstStepItems[filterOptions.firstStepValues.value].subLabels = [option, subLabels[1]];
      }
      let newAvailableItems = filterOptions.availableSecondStepItems;
      if (isMultiple && filterOptions.firstStepValues.type !== 'all' && defaultStep !== 0) {
        newAvailableItems = filterOptions.availableSecondStepItems.map(item => {
          if (item.valueId === option.valueId) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        });
        const sortAlphabetically = (a, b) => {
          return (
            a.valueName.localeCompare(b.valueName, 'en', { numeric: true, sensitivity: 'base' }) ||
            a.valueName.localeCompare(b.valueName, 'ru', { numeric: true, sensitivity: 'base' })
          );
        };

        const [checkedItems, uncheckedItems] = newAvailableItems.reduce(
          ([checked, unchecked], item) => {
            if (item.isChecked) {
              checked.push(item);
            } else {
              unchecked.push(item);
            }
            return [checked, unchecked];
          },
          [[], []],
        );
        checkedItems.sort(sortAlphabetically);
        uncheckedItems.sort(sortAlphabetically);

        newAvailableItems = [...checkedItems, ...uncheckedItems];
      }

      setFilterOptions(prevState => {
        return {
          ...prevState,
          chosenFirstStepItems: newChosenFirstStepItems,
          availableSecondStepItems: newAvailableItems,
        };
      });
      setValues(newChosenFirstStepItems);
      forceUpdate();
    },
    [getNewRowType, defaultStep, filterOptions, setValues],
  );

  const { current: props } = useRef({
    popper: params => {
      return <Box className={classes.autoCompletePaper}>{params.children}</Box>;
    },
    paper: params => <Box className={classes.autoCompletePaper}>{params.children}</Box>,
    optionLabel: option => option.label,
    renderOption: params => (
      <Box {...params} key={params['data-option-index']}>
        <Typography variant="h5" whiteSpace="nowrap" textOverflow="ellipsis">
          {params.key}
        </Typography>
      </Box>
    ),
    renderFilterMethod: (option, value) => {
      return option.value === value.value;
    },
  });

  const renderTypeOption = useCallback(type => {
    switch (type) {
      case 'boolean':
      case '1bool':
      case 'activity':
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="eq">соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="noteq">не соответствует</option>
          </>
        );
      case 'issue_status':
      case 'status':
      case 'list':
      case 'user':
      case 'project':
      case 'parent':
      case 'tracker':
      case 'user_list':
      case 'contract_issues':
      case 'calculation_types':
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="eq">соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="noteq">не соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="null">отсутствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="all">все</option>
          </>
        );
      case 'issues':
      case 'issue_priority':
      case 'user_roles':
      case 'user_groups':
      case 'user_watchers':
      case 'issue_watchers':
      case 'user_groups':
      case 'issue_priority':
      case 'user_roles':
      case 'rp_issues':
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="eq">соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="noteq">не соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="null">отсутствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="all">все</option>
          </>
        );
      case 'int':
      case 'float':
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="eq">соответствует</option>
            <option value="gte">{'>='}</option>
            <option value="lte">{'<='}</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="btw">между</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="null">отсутствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="all">все</option>
          </>
        );
      case 'spent_on':
      case 'date':
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="eq">соответствует</option>
            <option value="gte">{'>='}</option>
            <option value="lte">{'<='}</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="btw">между</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="Today">сегодня</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="Yesterday">вчера</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="ThisWeek">на этой неделе</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="LastWeek">прошлая неделя</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="ThisMonth">этот месяц</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="LastMonth">прошлый месяц</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="ThisYear">этот год</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="null">отсутствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="all">все</option>
          </>
        );
      case 'string':
      case 'text':
      case 'file':
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="in">содержит</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="notin">не содержит</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="startswith">начинается с</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="endswith">заканчивается на</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="null">отсутствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="all">все</option>
          </>
        );
      case 'issue':
        return (
          <>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="eq">соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="noteq">не соответствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="null">отсутствует</option>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <option value="all">все</option>
          </>
        );
      default:
        return <option>{type}</option>;
    }
  }, []);

  const handleChangeFilterType = useCallback(
    e => {
      setIsDefaultFirstStepOpen(true);
      let newAvailableItems = filterOptions.availableSecondStepItems;
      if (defaultStep !== 0) {
        newAvailableItems = filterOptions.availableSecondStepItems.map(item => {
          if (item.isChecked) {
            return { ...item, isChecked: false };
          }
          return item;
        });
      }
      let chosenFirstStepItems;
      let defaultItemName = `${filterOptions.firstStepValues.value}_`;
      chosenFirstStepItems = {
        ...filterOptions.chosenFirstStepItems,
        [defaultItemName]: {
          ...filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value],
          ...filterOptions.chosenFirstStepItems[defaultItemName],
          type: e.target.value,
          notParsedType: e.target.value,
          valueName: filterOptions.firstStepValues.valueName,
          label: filterOptions.firstStepValues.valueName,
          value: filterOptions.firstStepValues.value,
          fieldType: filterOptions.firstStepValues.fieldType,
          subLabels:
            filterOptions.chosenFirstStepItems[defaultItemName]?.subLabels?.length > 0
              ? e.target.value.split('').includes('-')
                ? []
                : [filterOptions.chosenFirstStepItems[defaultItemName].subLabels[0]]
              : [],
        },
      };
      if (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.hasOwnProperty('type')) {
        delete chosenFirstStepItems[filterOptions.firstStepValues.value];
        setIsClearDate(true);
        setTimeout(() => setIsClearDate(false), 100);
      }
      dayjs.extend(weekday);
      switch (e.target.value) {
        case 'Today':
          setIsDefaultFirstStepOpen(false);
          setIsSecondStepPopperOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'eq',
            subLabels: [
              {
                valueName: dayjs().format('DD.MM.YYYY'),
                valueId: dayjs().format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        // case 'nd':
        //   setIsSecondStepPopperOpen(false);
        //   setIsDefaultFirstStepOpen(false);
        //   chosenFirstStepItems[filterOptions.firstStepValues.value] = {
        //     ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
        //     type: 'eq',
        //     subLabels: [
        //       {
        //         valueName: dayjs().add(1, 'd').format('DD.MM.YYYY'),
        //         valueId: dayjs().add(1, 'd').format('YYYY-MM-DD'),
        //       },
        //     ],
        //   };
        //   delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
        //   break;
        case 'Yesterday':
          setIsSecondStepPopperOpen(false);
          setIsDefaultFirstStepOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'eq',
            subLabels: [
              {
                valueName: dayjs().subtract(1, 'd').format('DD.MM.YYYY'),
                valueId: dayjs().subtract(1, 'd').format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'ThisWeek':
          setIsSecondStepPopperOpen(false);
          setIsDefaultFirstStepOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().weekday(0).format('DD.MM.YYYY'),
                valueId: dayjs().weekday(0).format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().weekday(6).format('DD.MM.YYYY'),
                valueId: dayjs().weekday(6).format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'LastWeek':
          setIsSecondStepPopperOpen(false);
          setIsDefaultFirstStepOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().subtract(1, 'w').weekday(0).format('DD.MM.YYYY'),
                valueId: dayjs().subtract(1, 'w').weekday(0).format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().subtract(1, 'w').weekday(6).format('DD.MM.YYYY'),
                valueId: dayjs().subtract(1, 'w').weekday(6).format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'LastTwoWeeks':
          setIsSecondStepPopperOpen(false);
          setIsDefaultFirstStepOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().subtract(2, 'w').weekday(0).format('DD.MM.YYYY'),
                valueId: dayjs().subtract(2, 'w').weekday(0).format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().subtract(1, 'w').weekday(6).format('DD.MM.YYYY'),
                valueId: dayjs().subtract(1, 'w').weekday(6).format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'ThisMonth':
          setIsSecondStepPopperOpen(false);
          setIsDefaultFirstStepOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().startOf('month').format('DD.MM.YYYY'),
                valueId: dayjs().startOf('month').format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().endOf('month').format('DD.MM.YYYY'),
                valueId: dayjs().endOf('month').format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'LastMonth':
          setIsDefaultFirstStepOpen(false);
          setIsSecondStepPopperOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().subtract(1, 'month').startOf('month').format('DD.MM.YYYY'),
                valueId: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().subtract(1, 'month').endOf('month').format('DD.MM.YYYY'),
                valueId: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'ThisYear':
          setIsDefaultFirstStepOpen(false);
          setIsSecondStepPopperOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().startOf('year').format('DD.MM.YYYY'),
                valueId: dayjs().startOf('year').format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().endOf('year').format('DD.MM.YYYY'),
                valueId: dayjs().endOf('year').format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'y':
          setIsDefaultFirstStepOpen(false);
          setIsSecondStepPopperOpen(false);
          chosenFirstStepItems[filterOptions.firstStepValues.value] = {
            ...chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
            type: 'btw',
            subLabels: [
              {
                valueName: dayjs().startOf('year').format('DD.MM.YYYY'),
                valueId: dayjs().startOf('year').format('YYYY-MM-DD'),
              },
              {
                valueName: dayjs().endOf('year').format('DD.MM.YYYY'),
                valueId: dayjs().endOf('year').format('YYYY-MM-DD'),
              },
            ],
          };
          delete chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`];
          break;
        case 'btw':
          break;
        case '>t-':
          setIsSecondStepPopperOpen(true);
          chosenFirstStepItems[defaultItemName].type = 'gt';
          break;
        case '<t-':
          setIsSecondStepPopperOpen(true);
          chosenFirstStepItems[defaultItemName].type = 'lt';
          break;
        case '><t-':
          setIsSecondStepPopperOpen(true);
          chosenFirstStepItems[defaultItemName].type = 'gt';
          break;
        case 't-':
          setIsSecondStepPopperOpen(true);
          chosenFirstStepItems[defaultItemName].type = 'eq';
          break;
      }
      if (e.target.value === 'all' || e.target.value === 'null') {
        chosenFirstStepItems[filterOptions.firstStepValues.value] = {
          ...chosenFirstStepItems[defaultItemName],
          subLabels: [null],
        };
        delete chosenFirstStepItems[defaultItemName];
        setIsDefaultFirstStepOpen(false);
        setIsSecondStepPopperOpen(false);
      }
      setFilterOptions(prevState => {
        return {
          ...prevState,
          chosenFirstStepItems,
          firstStepValues: {
            ...prevState.firstStepValues,
            type: e.target.value,
          },
          availableSecondStepItems: newAvailableItems,
        };
      });
      setValues(chosenFirstStepItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterOptions.chosenFirstStepItems, filterOptions.firstStepValues, setValues],
  );

  const handleOpenFirstDefaultStepFilter = useCallback(
    e => {
      setIsDefaultFirstStepOpen(true);
      defaultStep === 1 && setAnchorEl(e.currentTarget);
      if (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]) {
        return;
      }
      setFilterOptions(prevState => {
        return {
          ...prevState,
          chosenFirstStepItems: {
            ...prevState.chosenFirstStepItems,
            [`${prevState.firstStepValues.value}_`]: {
              ...prevState.chosenFirstStepItems[`${prevState.firstStepValues.value}_`],
              label: prevState.firstStepValues.valueName,
              value: prevState.firstStepValues.value,
              type: prevState.firstStepValues.type,
              notParsedType: prevState.firstStepValues.type,
              subLabels: [],
            },
          },
        };
      });
      setValues({
        ...filterOptions.chosenFirstStepItems,
        [`${filterOptions.firstStepValues.value}_`]: {
          ...filterOptions.chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`],
          label: filterOptions.firstStepValues.valueName,
          value: filterOptions.firstStepValues.value,
          type: filterOptions.firstStepValues.type,
          notParsedType: filterOptions.firstStepValues.type,
          subLabels: [],
        },
      });
    },
    [
      defaultStep,
      filterOptions.chosenFirstStepItems,
      filterOptions.firstStepValues.type,
      filterOptions.firstStepValues.value,
      filterOptions.firstStepValues.valueName,
      setValues,
    ],
  );

  const renderSecondStepInput = useCallback(
    ({ value, open, onOpen, renderInput, PaperComponent, PopperComponent, renderOption, className, placeholder }) => {
      const autocompleteParams = {
        onChange: (e, newValue, _, { option }) => handleChooseSubItem({ option }),
        noOptionsText: (
          <Typography variant="h5">
            <FormattedMessage id="no_results" />
          </Typography>
        ),
      };
      const dateParams = {
        renderDay: (date, selectedDays, props) => {
          const onDaySelect = selectedDate => {
            handleChooseSubItem({
              option: {
                valueName: dayjs(selectedDate).format('DD.MM.YYYY'),
                valueId: dayjs(selectedDate).format('YYYY-MM-DD'),
              },
              isMultiple: true,
            });
          };
          return (
            <PickersDay
              {...props}
              key={date}
              outsideCurrentMonth={false}
              day={date}
              disableRipple
              onDaySelect={onDaySelect}
            />
          );
        },
        formatter: day => `${day.charAt(0).toLowerCase()}${day.charAt(1).toLowerCase()}`,
      };
      let currentType = filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.type || '';
      if (filterOptions.chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`]?.hasOwnProperty('type')) {
        currentType = filterOptions.chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`].type;
      }
      switch (value) {
        case 'spent_on':
        case 'date':
          return (
            <>
              <Button
                disableRipple
                variant="outlined"
                placeholder={placeholder}
                className={clsx(classes.filterInput, classes.filterInputBox, className, {
                  [defaultStep !== 0 && classes.filterDefaultActive]:
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType === 'null' ||
                    (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels?.length > 0 &&
                      filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId
                        .length > 0),
                })}
                onClick={handleOpenFirstDefaultStepFilter}
              >
                {placeholder}
              </Button>
              <Popper
                disablePortal
                open={open || currentType.split('').includes('-')}
                anchorEl={anchorEl}
                className={clsx(
                  classes.autoCompletePopper,
                  defaultStep === 1 && [classes.defaultFirstStepPaper, classes.dateFirstStepPaper],
                )}
                placement="bottom-start"
              >
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  borderBottom="1px solid rgba(228, 228, 239, 1)"
                  padding={2}
                >
                  <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                    {getTranslatedText(
                      intl,
                      'filter',
                      filterOptions.firstStepValues.value,
                      filterOptions.firstStepValues.valueName,
                    )}
                  </Typography>
                  <Select
                    native
                    value={filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType}
                    onChange={handleChangeFilterType}
                    className={classes.secondStepInput}
                    IconComponent={() => {
                      return (
                        <Box width={24} height={24} paddingRight={2} position="absolute" right={10}>
                          <ChevronIcon direction="down" viewBox="0 0 24 24" />
                        </Box>
                      );
                    }}
                    MenuProps={{
                      style: {
                        zIndex: 2222,
                      },
                    }}
                  >
                    {renderTypeOption(filterOptions.firstStepValues.fieldType)}
                  </Select>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FilterDateBetween
                    filterOptions={filterOptions}
                    dateParams={dateParams}
                    classes={classes}
                    handleChooseSubItem={handleChooseSubItem}
                    isClear={isClearDate}
                  />
                </LocalizationProvider>
              </Popper>
            </>
          );
        case 'resPlan_date_to':
          return (
            <>
              <Button
                disableRipple
                variant="outlined"
                placeholder={placeholder}
                className={clsx(classes.filterInput, classes.filterInputBox, className, {
                  [defaultStep !== 0 && classes.filterDefaultActive]:
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType === 'null' ||
                    (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels?.length > 0 &&
                      filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId
                        .length > 0),
                })}
                onClick={handleOpenFirstDefaultStepFilter}
              >
                {placeholder +
                  ' ' +
                  formatDateToMonthYear(
                    filterOptions?.chosenFirstStepItems['resourcePlan.date_to']?.subLabels[0]?.valueId,
                  )}
              </Button>
              <Popper
                disablePortal
                open={open || currentType.split('').includes('-')}
                anchorEl={anchorEl}
                className={clsx(
                  classes.autoCompletePopper,
                  defaultStep === 1 && [classes.defaultFirstStepPaper, classes.dateFirstStepPaper],
                )}
                placement="bottom-start"
              >
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  borderBottom="1px solid rgba(228, 228, 239, 1)"
                  padding={2}
                >
                  <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                    {getTranslatedText(
                      intl,
                      'filter',
                      filterOptions.firstStepValues.value,
                      filterOptions.firstStepValues.valueName,
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                  marginTop={4}
                  width="auto !important"
                >
                  <IconButton
                    width={24}
                    height={24}
                    onClick={() => {
                      const currentDate =
                        filterOptions?.chosenFirstStepItems['resourcePlan.date_to']?.subLabels[0]?.valueId;

                      if (currentDate) {
                        const newDate = dayjs(currentDate).subtract(1, 'year');

                        const dateOption = {
                          month: newDate.month() + 1,
                          year: newDate.year(),
                          valueName: newDate.format('DD.MM.YYYY'),
                          valueId: newDate.format('YYYY-MM-DD'),
                        };

                        handleChooseSubItem({
                          option: dateOption,
                          isMultiple: false,
                        });
                      }
                    }}
                  >
                    <ChevronIcon width={24} height={24} direction="left" viewBox="0 0 24 24" />
                  </IconButton>
                  <Typography variant="h4" fontWeight={400} whiteSpace="nowrap">
                    {formatDateToYear(
                      filterOptions?.chosenFirstStepItems['resourcePlan.date_to']?.subLabels[0]?.valueId,
                    )}
                  </Typography>
                  <IconButton
                    width={24}
                    height={24}
                    onClick={() => {
                      const currentDate =
                        filterOptions?.chosenFirstStepItems['resourcePlan.date_to']?.subLabels[0]?.valueId;

                      if (currentDate) {
                        const newDate = dayjs(currentDate).add(1, 'year');

                        const dateOption = {
                          month: newDate.month() + 1,
                          year: newDate.year(),
                          valueName: newDate.format('DD.MM.YYYY'),
                          valueId: newDate.format('YYYY-MM-DD'),
                        };

                        handleChooseSubItem({
                          option: dateOption,
                          isMultiple: false,
                        });
                      }
                    }}
                  >
                    <ChevronIcon width={24} height={24} direction="right" viewBox="0 0 24 24" />
                  </IconButton>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs} className={classes.calendarResplanRoot}>
                  <FilterDateBetween
                    showMonthPicker={true} // Добавьте эту строку, чтобы показать новый блок
                    filterOptions={filterOptions}
                    dateParams={dateParams}
                    classes={classes}
                    handleChooseSubItem={handleChooseSubItem}
                    isClear={isClearDate}
                  />
                </LocalizationProvider>
              </Popper>
            </>
          );
        case 'resPlan_date_from':
          return (
            <>
              <Button
                disableRipple
                variant="outlined"
                placeholder={placeholder}
                className={clsx(classes.filterInput, classes.filterInputBox, className, {
                  [defaultStep !== 0 && classes.filterDefaultActive]:
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType === 'null' ||
                    (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels?.length > 0 &&
                      filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId
                        .length > 0),
                })}
                onClick={handleOpenFirstDefaultStepFilter}
              >
                {placeholder +
                  ' ' +
                  formatDateToMonthYear(
                    filterOptions?.chosenFirstStepItems['resourcePlan.date_from']?.subLabels[0]?.valueId,
                  )}{' '}
              </Button>
              <Popper
                disablePortal
                open={open || currentType.split('').includes('-')}
                anchorEl={anchorEl}
                className={clsx(
                  classes.autoCompletePopper,
                  defaultStep === 1 && [classes.defaultFirstStepPaper, classes.dateFirstStepPaper],
                )}
                placement="bottom-start"
              >
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  borderBottom="1px solid rgba(228, 228, 239, 1)"
                  padding={2}
                >
                  <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                    {getTranslatedText(
                      intl,
                      'filter',
                      filterOptions.firstStepValues.value,
                      filterOptions.firstStepValues.valueName,
                    )}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                  marginTop={4}
                  width="auto !important"
                >
                  <IconButton
                    width={24}
                    height={24}
                    onClick={() => {
                      const currentDate =
                        filterOptions?.chosenFirstStepItems['resourcePlan.date_from']?.subLabels[0]?.valueId;

                      if (currentDate) {
                        const newDate = dayjs(currentDate).subtract(1, 'year');

                        const dateOption = {
                          month: newDate.month() + 1,
                          year: newDate.year(),
                          valueName: newDate.format('DD.MM.YYYY'),
                          valueId: newDate.format('YYYY-MM-DD'),
                        };

                        handleChooseSubItem({
                          option: dateOption,
                          isMultiple: false,
                        });
                      }
                    }}
                  >
                    <ChevronIcon width={24} height={24} direction="left" viewBox="0 0 24 24" />
                  </IconButton>
                  <Typography variant="h4" fontWeight={400} whiteSpace="nowrap">
                    {formatDateToYear(
                      filterOptions?.chosenFirstStepItems['resourcePlan.date_from']?.subLabels[0]?.valueId,
                    )}
                  </Typography>
                  <IconButton
                    width={24}
                    height={24}
                    onClick={() => {
                      const currentDate =
                        filterOptions?.chosenFirstStepItems['resourcePlan.date_from']?.subLabels[0]?.valueId;

                      if (currentDate) {
                        const newDate = dayjs(currentDate).add(1, 'year');

                        const dateOption = {
                          month: newDate.month() + 1,
                          year: newDate.year(),
                          valueName: newDate.format('DD.MM.YYYY'),
                          valueId: newDate.format('YYYY-MM-DD'),
                        };

                        handleChooseSubItem({
                          option: dateOption,
                          isMultiple: false,
                        });
                      }
                    }}
                  >
                    <ChevronIcon width={24} height={24} direction="right" viewBox="0 0 24 24" />
                  </IconButton>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs} className={classes.calendarResplanRoot}>
                  <FilterDateBetween
                    showMonthPicker={true} // Добавьте эту строку, чтобы показать новый блок
                    filterOptions={filterOptions}
                    dateParams={dateParams}
                    classes={classes}
                    handleChooseSubItem={handleChooseSubItem}
                    isClear={isClearDate}
                  />
                </LocalizationProvider>
              </Popper>
            </>
          );
        case 'string':
        case 'text':
        case 'file':
          return (
            <>
              <Button
                disableRipple
                variant="outlined"
                placeholder={placeholder}
                className={clsx(classes.filterInput, classes.filterInputBox, className, {
                  [defaultStep !== 0 && classes.filterDefaultActive]:
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType === 'null' ||
                    (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels?.length > 0 &&
                      filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId
                        .length > 0),
                })}
                onClick={handleOpenFirstDefaultStepFilter}
              >
                {placeholder}
              </Button>
              <Popper
                disablePortal
                open={isDefaultFirstStepOpen}
                anchorEl={anchorEl}
                className={classes.autoCompletePopper}
                placement="bottom-start"
              >
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  borderBottom="1px solid rgba(228, 228, 239, 1)"
                  padding={2}
                >
                  <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                    {getTranslatedText(
                      intl,
                      'filter',
                      filterOptions.firstStepValues.value,
                      filterOptions.firstStepValues.valueName,
                    )}
                  </Typography>
                  <Select
                    native
                    value={filterOptions.firstStepValues.type}
                    onChange={handleChangeFilterType}
                    className={classes.secondStepInput}
                    IconComponent={() => {
                      return (
                        <Box width={24} height={24} paddingRight={2} position="absolute" right={10}>
                          <ChevronIcon direction="down" viewBox="0 0 24 24" />
                        </Box>
                      );
                    }}
                    MenuProps={{
                      style: {
                        zIndex: 2222,
                      },
                    }}
                  >
                    {renderTypeOption(filterOptions.firstStepValues.fieldType)}
                  </Select>
                </Grid>
                <TextField
                  onChange={e =>
                    handleChooseSubItem({
                      option: { valueName: e.target.value, valueId: e.target.value },
                      isMultiple: false,
                    })
                  }
                  variant="outlined"
                  value={
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId || ''
                  }
                  placeholder={placeholder}
                  className={clsx(classes.filterInput, classes.filterSecondInput)}
                />
              </Popper>
            </>
          );
        case 'bool':
        case 'boolean':
          return (
            <>
              <Button
                disableRipple
                variant="outlined"
                placeholder={placeholder}
                className={clsx(classes.filterInput, classes.filterInputBox, className, {
                  [defaultStep !== 0 && classes.filterDefaultActive]:
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType === 'null' ||
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels?.length > 0 ||
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId
                      .length > 0,
                })}
                onClick={handleOpenFirstDefaultStepFilter}
              >
                {placeholder}
              </Button>
              <Popper
                disablePortal
                open={isDefaultFirstStepOpen}
                anchorEl={anchorEl}
                className={classes.autoCompletePopper}
                placement="bottom-start"
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                  borderBottom="1px solid rgba(228, 228, 239, 1)"
                  padding={2}
                >
                  <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                    {getTranslatedText(
                      intl,
                      'filter',
                      filterOptions.firstStepValues.value,
                      filterOptions.firstStepValues.valueName,
                    )}
                  </Typography>
                  <Button
                    className={classes.boolClearButton}
                    variant="defaultGreyPrimary"
                    onClick={() => handleChooseSubItem({ option: { delete: true } })}
                  >
                    {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                    <Typography variant="h5">Очистить</Typography>
                  </Button>
                </Grid>
                <Grid container wrap="nowrap" alignItems="center">
                  <RadioGroup
                    onChange={e => {
                      handleChooseSubItem({
                        option: {
                          valueName: getTranslatedText(intl, '', e.target.value, e.target.value),
                          valueId: e.target.value,
                        },
                        isMultiple: false,
                      });
                    }}
                  >
                    <FormControlLabel
                      className={classes.booleanLabel}
                      value="true"
                      checked={
                        filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]
                          ?.valueId === 'true'
                      }
                      control={
                        <Radio
                          disableRipple
                          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                        />
                      }
                      label={
                        <Typography variant="h5">
                          <FormattedMessage id="yes" />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={classes.booleanLabel}
                      value="false"
                      checked={
                        filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]
                          ?.valueId === 'false'
                      }
                      control={
                        <Radio
                          disableRipple
                          icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                          checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
                        />
                      }
                      label={
                        <Typography variant="h5">
                          <FormattedMessage id="no" />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </Popper>
            </>
          );
        case 'int':
        case 'float':
          return (
            <>
              <Button
                disableRipple
                variant="outlined"
                placeholder={placeholder}
                className={clsx(classes.filterInput, classes.filterInputBox, className, {
                  [defaultStep !== 0 && classes.filterDefaultActive]:
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType === 'null' ||
                    (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels?.length > 0 &&
                      filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId
                        .length > 0),
                })}
                onClick={handleOpenFirstDefaultStepFilter}
              >
                {placeholder}
              </Button>
              <Popper
                disablePortal
                open={isDefaultFirstStepOpen}
                anchorEl={anchorEl}
                className={classes.autoCompletePopper}
                placement="bottom-start"
              >
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  borderBottom="1px solid rgba(228, 228, 239, 1)"
                  padding={2}
                >
                  <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                    {getTranslatedText(
                      intl,
                      'filter',
                      filterOptions.firstStepValues.value,
                      filterOptions.firstStepValues.valueName,
                    )}
                  </Typography>
                  <Select
                    native
                    value={filterOptions.firstStepValues.type}
                    onChange={handleChangeFilterType}
                    className={classes.secondStepInput}
                    IconComponent={() => {
                      return (
                        <Box width={24} height={24} paddingRight={2} position="absolute" right={10}>
                          <ChevronIcon direction="down" viewBox="0 0 24 24" />
                        </Box>
                      );
                    }}
                    MenuProps={{
                      style: {
                        zIndex: 2222,
                      },
                    }}
                  >
                    {renderTypeOption(filterOptions.firstStepValues.fieldType)}
                  </Select>
                </Grid>
                <TextField
                  value={
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId || ''
                  }
                  onChange={e => {
                    handleChooseSubItem({
                      option: { valueName: e.target.value, valueId: e.target.value },
                      isMultiple: false,
                      type: 1,
                      itemIndex: 0,
                    });
                  }}
                  variant="outlined"
                  placeholder={placeholder}
                  className={clsx(classes.filterInput, classes.filterSecondInput)}
                  type="number"
                  step={0.1}
                />
                {currentType === 'btw' && (
                  <TextField
                    value={
                      filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[1]?.valueId ||
                      ''
                    }
                    onChange={e => {
                      handleChooseSubItem({
                        option: { valueName: e.target.value, valueId: e.target.value },
                        isMultiple: false,
                        type: 1,
                        itemIndex: 1,
                      });
                    }}
                    variant="outlined"
                    placeholder={placeholder}
                    className={clsx(classes.filterInput, classes.filterSecondInput)}
                    type="number"
                    step={0.1}
                  />
                )}
              </Popper>
            </>
          );
        case 'group_by':
          if (defaultStep === 1) {
            return (
              <ToggleButtonGroup
                name={filterOptions.firstStepValues.valueName}
                value={
                  filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId || ''
                }
                className={classes.groupByFilter}
                exclusive
                aria-label={filterOptions.firstStepValues.valueName}
                onChange={e => {
                  const newValue = e.target.value;
                  const currentValue =
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId ||
                    '';

                  if (newValue === currentValue) {
                    return;
                  }

                  handleChooseSubItem({
                    option: { valueName: newValue, valueId: newValue },
                    isMultiple: false,
                  });
                }}
              >
                {filterOptions.availableSecondStepItems.map((filter, index) => {
                  const statusLabelTranslated = intl.formatMessage({ id: filter.valueName });
                  return (
                    <ToggleButton key={index} value={filter.valueId} className={classes.resPlanToggle}>
                      {statusLabelTranslated}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            );
          }
        case 'status':
          if (defaultStep === 1) {
            return (
              <ToggleButtonGroup
                name={filterOptions.firstStepValues.valueName}
                value={
                  filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.valueId || ''
                }
                className={classes.StatusFilterItem}
                exclusive
                aria-label={filterOptions.firstStepValues.valueName}
                onChange={e => {
                  handleChooseSubItem({
                    option: { valueName: e.target.value, valueId: e.target.value },
                    isMultiple: false,
                  });
                }}
              >
                {filterOptions.availableSecondStepItems.map((filter, index) => {
                  const statusLabelTranslated = intl.formatMessage({ id: filter.valueName });
                  return (
                    <ToggleButton key={index} value={filter.valueId}>
                      {statusLabelTranslated}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            );
          }
        default:
          return (
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              open={open}
              onOpen={onOpen}
              clearIcon={null}
              renderInput={renderInput}
              renderOption={renderOption}
              PaperComponent={PaperComponent}
              PopperComponent={PopperComponent}
              onChange={autocompleteParams.onChange}
              getOptionLabel={option => option.valueName}
              noOptionsText={autocompleteParams.noOptionsText}
              options={filterOptions.availableSecondStepItems}
              value={Object.values(filterOptions.chosenFirstStepItems)}
              isOptionEqualToValue={(option, value) => {
                if (value.value === filterOptions.firstStepValues.value) {
                  return Boolean(
                    value.subLabels.find(label => {
                      if (!label) {
                        return;
                      }
                      return `${label.valueId}` === `${option.valueId}`;
                    }),
                  );
                }
                return false;
              }}
              ChipProps={{
                className: classes.subItemChip,
                deleteIcon: null,
              }}
            />
          );
      }
    },
    [
      filterOptions,
      defaultStep,
      handleChooseSubItem,
      classes,
      handleOpenFirstDefaultStepFilter,
      isDefaultFirstStepOpen,
      anchorEl,
      intl,
      handleChangeFilterType,
      renderTypeOption,
      isClearDate,
    ],
  );

  const debouncedValueLogging = useDebouncedFunction(newValue => handleGetNewQueryIssues({ value: newValue }), 500);

  const handleChangeSecondStepInput = e => {
    debouncedValueLogging(e.target.value);
    setFilterOptions(prevState => ({
      ...prevState,
      secondStepInputText: e.target.value,
      availableSecondStepItems: e.target.value === '' ? [] : prevState.availableSecondStepItems,
    }));
  };

  let arrowIconShow =
    filterOptions.firstStepValues.fieldType === 'list' ||
    filterOptions.firstStepValues.fieldType === 'user_list' ||
    filterOptions.firstStepValues.fieldType === 'issues' ||
    filterOptions.firstStepValues.fieldType === 'contract_issues' ||
    filterOptions.firstStepValues.fieldType === 'project' ||
    filterOptions.firstStepValues.fieldType === 'parent' ||
    filterOptions.firstStepValues.fieldType === 'user' ||
    filterOptions.firstStepValues.fieldType === 'issue_status' || //for agileFilters
    filterOptions.firstStepValues.fieldType === 'calculation_types' ||
    filterOptions.firstStepValues.fieldType === 'issue' ||
    filterOptions.firstStepValues.fieldType === 'tracker' ||
    filterOptions.firstStepValues.fieldType === 'issue_priority' ||
    filterOptions.firstStepValues.fieldType === 'issue_watchers';
  return (
    <>
      {defaultStep === 1 && (
        <>
          <ClickAwayListener onClickAway={handleCloseAll}>
            <Box width="100%" position="relative">
              {renderSecondStepInput({
                value: filterOptions.firstStepValues.fieldType,
                open: isDefaultFirstStepOpen,
                onOpen: handleOpenFirstDefaultStepFilter,
                renderInput: params => {
                  return (
                    <Box
                      key={params.value}
                      onFocus={params.inputProps.onFocus}
                      display="flex"
                      flexWrap="nowrap"
                      position="relative"
                    >
                      <TextField
                        variant="outlined"
                        {...params}
                        placeholder={getTranslatedText(
                          intl,
                          'filter',
                          filterOptions.firstStepValues.value,
                          filterOptions.firstStepValues.valueName,
                        )}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            width: '100%',
                          },
                          onChange: e => {
                            if (filterOptions.firstStepValues.fieldType === 'issue') {
                              handleChangeSecondStepInput(e);
                            }
                            return params.inputProps.onChange(e);
                          },
                          [filterOptions.firstStepValues.fieldType === 'issue' && 'value']:
                            filterOptions.secondStepInputText,
                        }}
                        className={clsx(classes.filterInput, classes.filterDefaultFirstStepInput, {
                          [classes.filterDefaultActive]:
                            filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.notParsedType ===
                              'null' ||
                            (filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels !==
                              'undefined' &&
                              filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels
                                ?.length > 0 &&
                              filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]
                                ?.valueId?.length > 0),
                        })}
                      />
                      {arrowIconShow && (
                        <Box
                          width={24}
                          height={24}
                          position="absolute"
                          zIndex={1990}
                          right={10}
                          top={0}
                          bottom={0}
                          margin="auto"
                          className={classes.typeArrow}
                        >
                          <ChevronIcon viewBox="0 0 24 24" direction="down" />
                        </Box>
                      )}
                    </Box>
                  );
                },
                PaperComponent: params => (
                  <Box
                    className={clsx(classes.autoCompletePopper, classes.defaultFirstStepPaper)}
                    visibility={!isDefaultFirstStepOpen ? 'hidden' : 'unset'}
                  >
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      borderBottom="1px solid rgba(228, 228, 239, 1)"
                      padding={2}
                    >
                      <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                        {getTranslatedText(
                          intl,
                          'filter',
                          filterOptions.firstStepValues.value,
                          filterOptions.firstStepValues.valueName,
                        )}
                      </Typography>
                      <Select
                        native
                        value={filterOptions.firstStepValues.type}
                        onChange={handleChangeFilterType}
                        className={classes.secondStepInput}
                        IconComponent={() => {
                          return (
                            <Box width={24} height={24} paddingRight={2} position="absolute" right={10}>
                              <ChevronIcon direction="down" viewBox="0 0 24 24" />
                            </Box>
                          );
                        }}
                        MenuProps={{
                          style: {
                            zIndex: 2222,
                          },
                        }}
                      >
                        {renderTypeOption(filterOptions.firstStepValues.fieldType)}
                      </Select>
                    </Grid>
                    {params.children}
                  </Box>
                ),
                PopperComponent: props.popper,
                renderOption: props.renderOption,
                className: classes.filterDefaultFirstStepInput,
                placeholder: getTranslatedText(
                  intl,
                  'filter',
                  filterOptions.firstStepValues.value,
                  filterOptions.firstStepValues.valueName,
                ),
              })}
            </Box>
          </ClickAwayListener>
        </>
      )}
      {defaultStep === 0 && (
        <>
          <ClickAwayListener onClickAway={handleCloseAll}>
            <Box>
              <Autocomplete
                multiple
                disablePortal
                disableClearable
                open={isFirstStepPopperOpen}
                onChange={handleChangeFilter}
                renderOption={(params, option, { selected }) => (
                  <Box {...params} key={params['data-option-index']} disabled={true}>
                    <Typography disabled={option.locked} variant="h5" whiteSpace="nowrap" textOverflow="ellipsis">
                      {params.key}
                    </Typography>
                  </Box>
                )}
                getOptionDisabled={option => !!option.locked}
                getOptionLabel={props.optionLabel}
                onOpen={handleOpenFilterFirstStep}
                className={classes.filterAutoComplete}
                options={filterOptions.availableFirstStepItems}
                isOptionEqualToValue={props.renderFilterMethod}
                value={Object.values(filterOptions.chosenFirstStepItems)}
                PopperComponent={props.popper}
                ChipProps={{
                  className: classes.filterChip,
                  deleteIcon: (
                    <Box width={16} height={16} position="relative" zIndex={2000}>
                      <ClearIcon viewBox="0 0 24 24" color="primary" />
                    </Box>
                  ),
                }}
                renderTags={(values, _, state) => {
                  return values.map(value => {
                    return (
                      <Chip
                        style={{ cursor: 'pointer' }}
                        data-item-id={value.value}
                        disabled={value.locked}
                        key={value.value}
                        onDelete={e => {
                          let newType = e.currentTarget.attributes['data-item-id'].value;
                          if (!filterOptions.chosenFirstStepItems[newType]?.hasOwnProperty('value')) {
                            newType = `${newType}_`;
                          }
                          e.preventDefault();
                          e.stopPropagation();
                          return handleChooseSubItem({
                            option: {
                              delete: true,
                              item: filterOptions.chosenFirstStepItems[newType].value,
                            },
                          });
                        }}
                        onMouseDown={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          let newType = e.currentTarget.attributes['data-item-id'].value;
                          if (newType === filterOptions.firstStepValues.value && isSecondStepPopperOpen) {
                            return;
                          }
                          if (!filterOptions.chosenFirstStepItems[newType]?.hasOwnProperty('value')) {
                            newType = `${newType}_`;
                          }
                          handleChangeFilter(e, _, _, {
                            option: {
                              label: filterOptions.chosenFirstStepItems[newType].label,
                              value: filterOptions.chosenFirstStepItems[newType].value,
                              valueName: filterOptions.chosenFirstStepItems[newType].valueName,
                              fieldType: filterOptions.chosenFirstStepItems[newType].fieldType,
                              type: filterOptions.chosenFirstStepItems[newType].type,
                              notParsedType: filterOptions.chosenFirstStepItems[newType].type,
                              locked: filterOptions.chosenFirstStepItems[newType].locked
                                ? filterOptions.chosenFirstStepItems[newType].locked
                                : false,
                              isUpdateFirstStep: true,
                              isUpSelectedItems: true,
                            },
                          });
                        }}
                        label={value.label}
                        {...{
                          ...state.ChipProps,
                          deleteIcon: {
                            ...state.ChipProps.deleteIcon,
                            props: {
                              ...state.ChipProps.deleteIcon.props,
                              'data-item-id': value.value,
                            },
                          },
                        }}
                      />
                    );
                  });
                }}
                renderInput={params => {
                  return (
                    <Box onMouseDown={params.inputProps.onMouseDown}>
                      <TextField
                        variant="outlined"
                        {...params}
                        inputProps={{ ...params.inputProps, disabled: true }}
                        placeholder={
                          Object.values(filterOptions.chosenFirstStepItems).length > 0
                            ? ''
                            : intl.formatMessage({ id: 'choose' })
                        }
                        className={classes.filterInput}
                      />
                    </Box>
                  );
                }}
                PaperComponent={params => {
                  return (
                    <Popper
                      disablePortal
                      open={isFirstStepPopperOpen}
                      anchorEl={anchorEl}
                      className={classes.autoCompletePopper}
                      placement="bottom-start"
                    >
                      <Box className={classes.autoCompletePaper}>{params.children}</Box>
                    </Popper>
                  );
                }}
                noOptionsText={
                  <Typography variant="h5">
                    <FormattedMessage id="no_results" />
                  </Typography>
                }
              />
              {!filtersOnlySelect ? (
                <Popper
                  disablePortal
                  open={isSecondStepPopperOpen}
                  anchorEl={anchorEl}
                  className={classes.autoCompletePopper}
                  placement="bottom-start"
                >
                  {isSecondStepLoading ? (
                    <Grid container alignItems="center" justifyContent="center" width="100%" minHeight="200px">
                      <CircularProgress color="secondary" />
                    </Grid>
                  ) : (
                    <Box>
                      <Grid container direction="column" wrap="nowrap">
                        <Grid
                          container
                          alignItems="center"
                          wrap="nowrap"
                          borderBottom="1px solid rgba(228, 228, 239, 1)"
                          padding={2}
                        >
                          <Typography variant="h5" fontWeight={700} marginRight={4} marginLeft={2} whiteSpace="nowrap">
                            {filterOptions.firstStepValues.valueName}
                          </Typography>
                          <Select
                            native
                            value={
                              filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.type ||
                              filterOptions.chosenFirstStepItems[`${filterOptions.firstStepValues.value}_`]?.type
                            }
                            onChange={handleChangeFilterType}
                            className={classes.secondStepInput}
                            IconComponent={() => {
                              return (
                                <Box width={24} height={24} paddingRight={2} position="absolute" right={10}>
                                  <ChevronIcon direction="down" viewBox="0 0 24 24" />
                                </Box>
                              );
                            }}
                            MenuProps={{
                              style: {
                                zIndex: 2222,
                              },
                            }}
                          >
                            {renderTypeOption(filterOptions.firstStepValues.fieldType)}
                          </Select>
                        </Grid>
                      </Grid>
                      {renderSecondStepInput({
                        value: filterOptions.firstStepValues.fieldType,
                        open: isSecondStepPopperOpen,
                        onOpen: null,
                        renderInput: params => {
                          return (
                            <Box display="flex" flexDirection="column" onMouseDown={params.inputProps.onMouseDown}>
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                placeholder={intl.formatMessage({ id: 'enter' })}
                                className={classes.filterInput}
                                inputProps={{
                                  ...params.inputProps,
                                  style: {
                                    width: '100%',
                                  },
                                  onChange: e => {
                                    if (filterOptions.firstStepValues.fieldType === 'issue') {
                                      handleChangeSecondStepInput(e);
                                    }
                                    return params.inputProps.onChange(e);
                                  },
                                  [filterOptions.firstStepValues.fieldType === 'issue' && 'value']:
                                    filterOptions.secondStepInputText,
                                }}
                              />
                              <Divider orientation="horizontal" flexItem />
                            </Box>
                          );
                        },
                        PaperComponent: props.paper,
                        PopperComponent: props.popper,
                        renderOption: props.renderOption,
                        className: classes.filterSecondInput,
                        placeholder: intl.formatMessage({ id: 'enter' }),
                      })}
                    </Box>
                  )}
                </Popper>
              ) : null}
            </Box>
          </ClickAwayListener>
        </>
      )}
    </>
  );
};

CustomFilter.PropTypes = {
  firstStepItems: propTypes.arrayOf(
    propTypes.shape({
      fieldId: propTypes.number,
    }),
  ),
};

export default memo(CustomFilter);
